import { render, staticRenderFns } from "./ParentVerifyEmailModal.vue?vue&type=template&id=27516097&scoped=true"
import script from "./ParentVerifyEmailModal.vue?vue&type=script&lang=js"
export * from "./ParentVerifyEmailModal.vue?vue&type=script&lang=js"
import style0 from "./ParentVerifyEmailModal.vue?vue&type=style&index=0&id=27516097&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27516097",
  null
  
)

export default component.exports
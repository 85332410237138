<template>
  <v-dialog
    v-model="show"
    max-width="400px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar
        dark
        color="#7253CF"
        :height="$vuetify.breakpoint.smAndUp ? '103' : '100px'"
      >
        <v-spacer></v-spacer>
        <v-toolbar-title class="modalTitle pl-6">
          <div>Verify your email id</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="align-self-baseline" icon @click="show = false">
          <v-icon size="28">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="text-align: center" class="px-16 pt-4">
        <div>Enter verification code that has been sent to {{ email }}</div>
        <v-form ref="verifyEmailForm" lazy-validation>
          <div class="mt-3 mb-2">
            <v-text-field
              :disabled="loading"
              v-model="otp"
              placeholder="Verification Code"
              type="text"
              outlined
              dense
              hide-details
              :rules="[rules.required]"
            ></v-text-field>
          </div>
          <div>
            <div class="d-flex justify-center">
              Didn’t received?
              <v-btn
                :disabled="loading"
                @click="onClickResendCodeHandler()"
                class="toggleForm-btn pa-0"
                height="20"
                text
                >Resend Code</v-btn
              >
            </div>
          </div>
          <div class="my-5">
            <v-btn
              :loading="loading"
              @click="onClickVerifyHandler()"
              width="100%"
              dark
              color="#CC0000"
            >
              <span style="text-transform: none; letter-spacing: 0px">
                Verify
              </span>
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {} from "@/constants/APIKeys";
import {} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
import authToken from "@/common/authToken";
import { API_COMMON_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "@/constants/urls";

export default {
  name: "ParentVerifyEmailModal",
  components: {},
  data() {
    return {
      rules: RULES,
      otp: "",
      loading: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getParentVerifyEmailModalShow",
      email: "publicDonationPage/getParentVerifyEmailModalEmail",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        if (!value) this.clearModal();
        return this.toggleModal({ show: value });
      },
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleParentVerifyEmailModal",
      updateUserDetails: "publicDonationPage/updateUserDetails",
      showToast: "snackBar/showToast",
    }),
    clearModal() {
      this.otp = "";
      this.loading = false;
    },
    onClickVerifyHandler() {
      if (this.$refs.verifyEmailForm.validate()) {
        const _this = this;
        _this.loading = true;
        /**
         * Success handler of API call
         */
        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);

          _this.showToast({ message: data.message, color: "s" });
          try {
            // Save token in local storage
            // localStorage.setItem("token", res.headers.token);
          } catch (e) {
            console.log(e);
          }

          /**
           * Redirect to specific user panel
           */
          console.log(authToken.decodedToken(), "DECODE");
          this.updateUserDetails({ user: authToken.decodedToken() });
          _this.showToast({ message: "Logged In", color: "s" });
          _this.$router.push({
            name: ROUTER_URL.publicParentDashboard.name,
          });
          _this.show = false;
          _this.loading = false;
        };
        /**
         * Failure handler of API call
         */
        const failureHandler = (res) => {
          console.log("Failure ", res);
          _this.loading = false;
        };
        let formData = {};
        formData["user_email"] = this.email;
        formData["otp"] = this.otp;

        /**
         * API Call for login
         */
        Axios.request_POST(
          API_COMMON_URL.verifyEmail,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },
    onClickResendCodeHandler() {
      const _this = this;
      _this.loading = true;
      /**
       * Success handler of API call
       */
      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        /**
         * Redirect to specific user panel
         */
        _this.showToast({ message: res.data.message, color: "s" });
        _this.loading = false;
      };
      /**
       * Failure handler of API call
       */
      const failureHandler = (res) => {
        console.log("Failure ", res);
        _this.loading = false;
      };
      let formData = {};
      formData["user_email"] = this.email;

      /**
       * API Call for login
       */
      Axios.request_GET(
        API_COMMON_URL.resendOTP,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
.modalTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 315%;
  color: #ffffff;
}
.toggleForm-btn {
  text-transform: none;
  text-decoration-line: underline;
  color: blue;
  font-weight: 500;
  letter-spacing: normal;
  padding: 0px;
  height: 20px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 219%;
    color: #ffffff;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
